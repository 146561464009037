import List from '@mui/material/List';
import type { WithExperimentConfig } from '@shutterstock/experiments-types';
import { withVisualizedExperiment } from '@shutterstock/experiments-visualizer';

import { II1333_SUBMENU_ITEMS, SUBMENU_ITEMS } from '@/components/SiteMenu/constants';
import { SubMenuItem } from '@/components/SiteMenu/SubMenuDrawer/SubMenuItem';

const feature = ({
  items = SUBMENU_ITEMS,
  isOffsetUser,
  drawerContentType,
}: {
  items?: any;
  isOffsetUser: boolean;
  drawerContentType: string;
}) => (
  <List>
    {items[drawerContentType].map((iconType: string) => (
      <SubMenuItem key={iconType} iconType={iconType} isOffsetUser={isOffsetUser} />
    ))}
  </List>
);

const Variant1 = ({ isOffsetUser, drawerContentType }: { isOffsetUser: boolean; drawerContentType: string }) =>
  feature({ items: II1333_SUBMENU_ITEMS, isOffsetUser, drawerContentType });

export const config: WithExperimentConfig = {
  feature,
  id: 'ii-1333-trends-tab',
  variations: [Variant1],
};

export const ExperimentII1333 = withVisualizedExperiment(config.feature, config.id, config.variations);
