import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { MenuAnnouncement } from '@/components/MenuAnnouncement';
import { MENU_ITEMS } from '@/constants/global';
import { ExperimentII1333 } from '@/experiments/II/II-1333';
import { useFetchUser } from '@/hooks/useFetchUser';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

import { MENU_ITEM_INFO, NON_DESKTOP_SITE_MENU_SECTIONS, SUBMENU_ITEMS } from '../constants';
import { itemHasSubMenu } from '../helpers';
import { SubMenuItem } from './SubMenuItem';

const classes = {
  nonDesktopMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  nonDesktopMenuSection: {
    display: 'flex',
    flexDirection: 'column',
    px: ({ spacing }) => spacing('s'),
  },
  drawerSubHeading: {
    color: ({ palette }) => palette.text.secondary,
    margin: ({ spacing }) => `calc(${spacing('m')} + ${spacing('xs')}) 0 0 ${spacing('m')}`,
  },
};

const CollapsableMenuItem = ({ iconType, isOffsetUser }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const SubMenuItemProps = {
    open,
    iconType,
    functionHandlerList: [handleClick],
  };

  return (
    <>
      <SubMenuItem key={iconType} {...SubMenuItemProps} noClick />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {SUBMENU_ITEMS[iconType].map((subIconType) => (
            <SubMenuItem key={subIconType} iconType={subIconType} isOffsetUser={isOffsetUser} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const SubMenuList = ({ drawerContentType }) => {
  const isNonDesktopView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isTabletView = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const { t } = useTranslation(NAMESPACE_COMMON);
  const { isOffsetUser } = useFetchUser();

  if (isNonDesktopView) {
    return (
      <Grid sx={classes.nonDesktopMenuContainer}>
        <Grid item sx={classes.nonDesktopMenuSection}>
          {isTabletView && <MenuAnnouncement />}
          <List>
            {NON_DESKTOP_SITE_MENU_SECTIONS.UPPER.map((iconType) =>
              itemHasSubMenu(iconType) ? (
                <CollapsableMenuItem key={iconType} iconType={iconType} isOffsetUser={isOffsetUser} />
              ) : (
                <SubMenuItem key={iconType} iconType={iconType} mainMenuItem isOffsetUser={isOffsetUser} />
              ),
            )}
          </List>
        </Grid>
        <Grid item>
          <Divider />
          <Grid item sx={classes.nonDesktopMenuSection}>
            <List>
              {NON_DESKTOP_SITE_MENU_SECTIONS.LOWER.map((iconType) => (
                <SubMenuItem key={iconType} iconType={iconType} mainMenuItem isOffsetUser={isOffsetUser} />
              ))}
            </List>
            {!isTabletView && <MenuAnnouncement />}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Box sx={classes.drawerSubHeading}>
        <Typography variant="body2" display="block" gutterBottom>
          {t(MENU_ITEM_INFO[drawerContentType].iconTextKey)}
        </Typography>
      </Box>

      {drawerContentType === MENU_ITEMS.INSIGHTS ? (
        <ExperimentII1333 items={SUBMENU_ITEMS} isOffsetUser={isOffsetUser} drawerContentType={MENU_ITEMS.INSIGHTS} />
      ) : (
        <List>
          {SUBMENU_ITEMS[drawerContentType].map((iconType) => (
            <SubMenuItem key={iconType} iconType={iconType} isOffsetUser={isOffsetUser} />
          ))}
        </List>
      )}
    </>
  );
};

SubMenuList.propTypes = {
  drawerContentType: PropTypes.string.isRequired,
};
