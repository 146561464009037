import getConfig from 'next/config';

const {
  publicRuntimeConfig: {
    contributorCarbonWeb,
    endpoints: { picdn, customer, contributorSupportCenter, enterprise, publicShutterstock, accounts },
  },
} = getConfig();

export const CONTRIBUTOR_LOGO = '/assets/images/contributor_header_logo.svg';
export const OFFSET_LOGO = '/assets/images/offset_header_logo.svg';
export const SHUTTERSTOCK_TITLE_LOGO = '/assets/images/shutterstock_title_logo.svg';
export const SITE_HEADER_HEIGHT = '56px';
export const SHUTTERSTOCK_LOGO_WHITE = '/assets/images/shutterstock_logo_white.svg';
export const PAYONEER_LOGO = '/assets/images/payoneer.svg';

// C2N site routes
export const SITE_ROUTES = {
  LOGGED_OUT_HOME_PAGE: '/',
  HOME_PAGE: '/dashboard',
  LOGOUT: '/logout',
  EARNINGS_PAGE: '/earnings',
  TOP_PERFORMERS: '/earnings/top-performers',
  PORTFOLIO_LANDING_PAGE: '/portfolio/not_submitted/photo',
  PORTFOLIO_LANDING_PAGE_NO_MEDIA_TYPE: '/portfolio/not_submitted',
  INSIGHTS_PAGE: '/insights',
  MY_REFERRALS: '/referrals',
  PAYMENT_HISTORY: '/payments',
  PAYOUTS_PAGE: '/payouts',
  TAX_CENTER: '/legal/taxes',
  NEW_TAX_FORM: '/legal/taxes?newForm=true',
  ACCOUNT_SETTINGS_PAGE: '/account/settings',
  PUBLIC_PROFILE_PAGE: '/account/public',
  UPLOAD_PAGE: '/upload',
  CONTACT_PAGE: '/contact',
  W8_TAX_FORM: '/legal/taxes/forms/w-8ben',
  W9_TAX_FORM: '/legal/taxes/forms/w-9',
  TAXES_TREATY_COUNTRIES: '/legal/taxes/treaty_countries',
  ERROR_404_PAGE: '/404',
  SIGN_UP_ADDRESS: '/signup/address',
  SIGNUP_WELCOME: '/signup/welcome',
  RELEASES: '/releases',
  COLLECTIONS: '/collections',
  DATA_CATALOG_PHOTO: '/data-catalog/photo',
  DATA_CATALOG_VIDEO: '/data-catalog/video',
  TRENDS: '/trends', // Teardown II-1333
};

export const MENU_ITEMS = {
  HOME: 'Home',
  EARNINGS: 'Earnings',
  EARNINGS_SUMMARY: 'EarningsSummary',
  MY_REFERRALS: 'MyReferrals',
  PAYMENT_HISTORY: 'PaymentHistory',
  TAX_CENTER: 'TaxCenter',
  PORTFOLIO: 'Portfolio',
  SUBMIT_CONTENT: 'SubmitContent',
  PENDING_CONTENT: 'PendingContent',
  REVIEWED_CONTENT: 'ReviewedContent',
  CATALOG_MANAGER: 'CatalogManager',
  CATALOG_DATA_MANAGER: 'CatalogDataManager',
  COLLECTIONS: 'Collections',
  MANAGE_RELEASES: 'ManageReleases',
  KEYWORD_SUGGESTIONS: 'KeywordSuggestions',
  INSIGHTS: 'Insights',
  TOP_PERFORMERS: 'TopPerformers',
  THE_SHOT_LIST: 'TheShotList',
  CONTRIBUTOR_BLOG: 'ContributorBlog',
  ACCOUNT: 'Account',
  ACCOUNT_SETTINGS: 'AccountSettings',
  PUBLIC_PROFILE: 'PublicProfile',
  HELP: 'Help',
  MORE: 'More',
  LOG_OUT: 'LogOut',
  CONTENT_BRIEFS: 'ContentBriefs',
  TRENDS: 'Trends', // Teardown II-1333
};

export const TAX_SUPPORT_EMAIL = 'mailto:taxhelp@shutterstock.com';
export const C2N_SITE_ROUTES_VALUES = Object.values(SITE_ROUTES);

// Routes used on other Shutterstock pages (including CCWeb). Routes that are the
// identical on both CCWeb and C2N should be added to SITE_ROUTES instead (and use
// LINK_TYPE.CCWEB_REDIRECT for that link, before cutover).
export const NON_C2N_ROUTES = {
  DASHBOARD_PAGE: '/dashboard',
  UPLOAD_PORTFOLIO_PAGE: '/upload/portfolio',
  CATALOG_MANAGER: '/catalog_manager',
  CATALOG_DATA_MANAGER: '/data_catalog',
  LEGAL_PAGE: '/legal',
  TERMS_OF_SERVICE: '/legal/terms',
  DOWNLOAD_MOBILE_APP: '/explore/contributor-mobile-app',
  EDIT: '/edit',
  PENDING: '/pending',
  REVIEWED: '/reviewed',
  KEYWORD_TOOL: '/edit/keyword-tool',
  THE_SHOT_LIST: '/explore/the-shot-list',
  MOBILE_APP: '/explore/contributor-mobile-app',
  PRIVACY: '/privacy',
  CONTRIBUTOR_SUPPORT: '/contributorsupport',
  TAX_CENTER: '/legal/taxes',
  SIGNUP_ADDRESS: '/signup/address?next=/dashboard',
  WELCOME: '/signup/welcome',
  TAXES_DOWNLOAD: '/legal/taxes/external/download',
  UPLOAD_FOOTAGE_PAGE: '/upload/footage',
  CONTENT_BRIEFS: '/explore/contributor-content-briefs ',
  LOGIN: '/login?landing_page=/dashboard',
  SIGN_UP: '/signup/?landing_page=/signup/continue-registration',
};

export const C2N_FOOTER_LINKS = {
  TERMS_OF_SERVICE: '/legal/terms',
  LEGAL_PAGE: '/legal',
  TAX_CENTER: '/legal/taxes',
  CONTACT_PAGE: '/contact',
  PAYOUTS_PAGE: '/payouts',
};

export const LINK_TYPE = {
  CONTRIBUTOR_BLOG: Symbol.for('ContributorBlog'), // see CONTRIBUTOR_BLOG_VALID_LANG_PATHS
  BLOG: Symbol.for('Blog'), // see BLOG_VALID_LANG_PATHS
  PICDN: Symbol.for('picdn'),
  CCWEB_REDIRECT: Symbol.for('CCWebRedirect'),
  SHUTTERSTOCK_NEXT: Symbol.for('ShutterstockNext'), // next-web site, match current environment
  SHUTTERSTOCK_NEXT_PUBLIC: Symbol.for('ShutterstockNextPublic'), // next-web site, production/public link
  SHUTTERSTOCK_REDIRECT: Symbol.for('ShutterstockRedirect'),
  CONTRIBUTOR_SUPPORT_CENTER: Symbol.for('SupportCenter'),
  ACCOUNTS: Symbol.for('Accounts'),
  ENTERPRISE: Symbol.for('Enterprise'),
};

export const TAX_RESOURCES = {
  W_8_BENE: 'https://www.irs.gov/pub/irs-pdf/fw8bene.pdf',
  W_8_BENE_INSTRUCTIONS: 'https://www.irs.gov/pub/irs-pdf/iw8bene.pdf',
  W_8_ECI: 'https://www.irs.gov/pub/irs-pdf/fw8eci.pdf',
  W_8_ECI_INSTRUCTIONS: 'https://www.irs.gov/pub/irs-pdf/iw8eci.pdf',
  W_8_IMY: 'https://www.irs.gov/pub/irs-pdf/fw8imy.pdf',
  W_8_IMY_INSTRUCTIONS: 'https://www.irs.gov/pub/irs-pdf/iw8imy.pdf',
  W_8_EXP: 'https://www.irs.gov/pub/irs-pdf/fw8exp.pdf',
  W_8_EXP_INSTRUCTIONS: 'https://www.irs.gov/pub/irs-pdf/iw8exp.pdf',
  SUPPORT_EMAIL: 'mailto:taxhelp@shutterstock.com',
  SUPPORT_W8_FAQS: '/s/article/W-8-Frequently-Asked-Questions',
  SUPPORT_1042_FAQS: '/s/article/1042-Frequently-Asked-Question',
  SUPPORT_W9_FAQS: '/s/article/W-9-Frequently-Asked-Questions',
  SUPPORT_REVIEWED_Q: '/s/article/How-do-I-know-if-my-submitted-tax-form-was-reviewed',
};

export const SUPPORT_GET_PAID = '/s/article/How-do-I-get-paid-for-my-work';

export const CONTRIBUTOR_BLOG_VALID_LANG_PATHS = {
  en: '/blog/contributors',
  fr: '/fr/blog/contributors',
  de: '/de/blog/anbieter',
  pt: '/pt/blog/contributors',
  ja: '/ja/blog/contributor',
  'zh-Hant': '/zh-Hant/blog/contributor',
  th: '/th/blog/contributor',
  ko: '/ko/blog/contributor',
  es: '/es/blog/colaborador',
  in: '/blog/india/contributor',
};

export const BLOG_VALID_LANG_PATHS = {
  en: '/blog',
  fr: '/fr/blog',
  de: '/de/blog',
  pt: '/pt/blog',
  ja: '/ja/blog',
  in: '/blog/india',
  'zh-Hant': '/zh-Hant/blog',
  th: '/th/blog',
  ko: '/ko/blog',
  es: '/es/blog',
};

export const edgeCaseURLFormatters = {
  [LINK_TYPE.CONTRIBUTOR_BLOG]: ({ locale }: { locale: string }) =>
    `${publicShutterstock}${
      CONTRIBUTOR_BLOG_VALID_LANG_PATHS[locale as keyof typeof CONTRIBUTOR_BLOG_VALID_LANG_PATHS] ||
      CONTRIBUTOR_BLOG_VALID_LANG_PATHS.en
    }`,
  [LINK_TYPE.BLOG]: ({ locale }: { locale: string }) =>
    `${publicShutterstock}${
      BLOG_VALID_LANG_PATHS[locale as keyof typeof BLOG_VALID_LANG_PATHS] || BLOG_VALID_LANG_PATHS.en
    }`,
};

export const baseURL = {
  [LINK_TYPE.PICDN]: picdn,
  [LINK_TYPE.CCWEB_REDIRECT]: contributorCarbonWeb,
  [LINK_TYPE.SHUTTERSTOCK_NEXT]: customer,
  [LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC]: publicShutterstock,
  [LINK_TYPE.SHUTTERSTOCK_REDIRECT]: publicShutterstock,
  [LINK_TYPE.CONTRIBUTOR_SUPPORT_CENTER]: contributorSupportCenter,
  [LINK_TYPE.ACCOUNTS]: accounts,
  [LINK_TYPE.ENTERPRISE]: enterprise,
};

export const prependPath = {
  [LINK_TYPE.SHUTTERSTOCK_NEXT]: (locale: string) => (locale ? `/${locale}` : ''),
  [LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC]: (locale: string) => (locale ? `/${locale}` : ''),
};

// Common article links
export const CONTRIBUTOR_DATAMARKETPLACE_SUPPORT_URL =
  '/s/article/Shutterstock-Data-Licensing-and-the-Contributor-Fund';

export const FOOTER_CONSTANTS = {
  COPYRIGHT: '© 2003-2021 Shutterstock, Inc. All rights reserved.',
  STATS_INFO: 'Over $1 billion paid to contributors since 2003.',
};

export const LANGUAGE_CONSTANTS = {
  DEFAULT_LANG: [{ code: 'en', label: 'English', translation_tag: 'english_language' }],
};
export const LANGUAGE_QUERY_KEYS = {
  [LINK_TYPE.CCWEB_REDIRECT]: 'language',
  [LINK_TYPE.SHUTTERSTOCK_REDIRECT]: 'l',
  [LINK_TYPE.CONTRIBUTOR_SUPPORT_CENTER]: 'language',
  [LINK_TYPE.ACCOUNTS]: 'language',
};

export const DEFAULT_CURRENCY_FORMAT = { style: 'currency', currency: 'USD' };
export const DEFAULT_DATE_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'UTC',
};

export const VALUE_TYPES = {
  NUMBER: 'number',
  CURRENCY: 'currency',
};

export const ASSET_TYPE_MAP = {
  CONTRIBUTOR: 'contributor',
  CUSTOMER: 'customer',
  IMAGE: 'image_set',
  VIDEO: 'video_set',
  NONE: 'none',
};

export const BASE64_PDF_PREFIX = 'data:application/pdf;base64,';

export const PRODUCT_TYPE_MAP = {
  '25_a_day': '25_a_day',
  enhanced: 'enhanced',
  on_demand: 'on_demand',
  single_image_and_other: 'single_image_and_other',
  footage_cart: 'cart_sales',
  footage_subscription: 'clip_packs',
  upfront_contributor_license: 'single_image_and_other',
  footage_enhanced: 'footage_enhanced',
  earning_pools_commission: 'earning_pools_commission',
};

export const REFERRAL_TYPE_MAP = {
  photo_referral: 'contributor_image_referrals',
  video_referral: 'contributor_video_referrals',
  customer_referral: 'customer_referrals',
  // It appears there are two possible keys for each referral type, hence the following keys:
  referred_downloads: 'contributor_image_referrals',
  referred_footage: 'contributor_video_referrals',
  referred_order: 'customer_referrals',
};

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const breadcrumbsForCurrentPath = {
  '/signup/address': [
    {
      label: 'contributor_home_page',
      href: '/dashboard',
    },
    {
      label: 'address',
    },
  ],
  '/collections/[collectionSubpage]': [
    {
      label: 'collections',
      href: SITE_ROUTES.COLLECTIONS,
      linkType: LINK_TYPE.CCWEB_REDIRECT,
    },
    {
      label: '',
    },
  ],
};

export const ACCEPTED_FILE_FORMATS = {
  OFFSET: '.jpg,.jpeg',
  SHUTTERSTOCK: '.jpg,.jpeg,.eps,.mov,.mp4',
  CSV: '.csv',
};

export const ALERT_TYPES = ['success', 'error', 'info', 'warning'];

export const IMAGES_ASSET_TYPE = 'images';
export const VIDEOS_ASSET_TYPE = 'videos';

export const ASSET_PORTFOLIO_TYPE_MAP = Object.freeze({
  images: 'photo',
  videos: 'video',
});

export const ASSET_TYPES = Object.values(ASSET_PORTFOLIO_TYPE_MAP);

export const CONTENTFUL_CONTENT_TYPES = {
  cta: 'linkButton',
  paragraphItem: 'imageParagraphItem',
};

export const SORT_DIRECTIONS = Object.freeze({
  ASCENDING: 'asc',
  DESCENDING: 'desc',
});

export const COLLECTION_TYPE_MAP = Object.freeze({
  images: 'photo',
  videos: 'video',
});

export const COLLECTION_TYPE = Object.freeze({
  photo: 'catalog_set',
  video: 'catalog_set_video',
});

export const Contributor = Object.freeze({
  Shutterstock: 'shutterstock',
  Offset: 'offset',
});

export const FEATURES = Object.freeze({ MARKETPLACE_RELEASE: 'marketplace-release' });

export const AssetActionType = Object.freeze({
  Save: Symbol('save'),
  Download: Symbol('download'),
  Submit: Symbol('submit'),
  Delete: Symbol('delete'),
  Edit: Symbol('edit'),
  View: Symbol('view'),
  SeeLargePreview: Symbol('seeLargePreview'),
});

export const EDITORIAL_SUPPORT_ARTICLE_URL =
  'https://support.submit.shutterstock.com/s/article/How-do-I-submit-editorial-content?language=__lang__';

export const CONTENT_PUBLISHING_STANDARDS_LEGAL_DOCUMENTATION_URL =
  'https://support.submit.shutterstock.com/s/article/Content-Publishing-Standards-Legal-Documentation?language=__lang__';

export const PORTFOLIO_TOOL_SUPPORT_ARTICLE_URL =
  'https://support.submit.shutterstock.com/s/article/Portfolio-Tools-Content-Editor?language=__lang__';

export const DISABLE_CLICK_AWAY_CLASS = 'disable-click-away';

export const MediaChannels = Object.freeze({
  DataMarketplace: 'data_marketplace',
});
